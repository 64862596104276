import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'


const Burger = styled.div`
.navigation__checkbox {
    display: none; }
  
  .navigation__button {
    background-color: #fff;
    height: 5rem;
    width: 5rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    border-radius: 50%;
    z-index: 2000;
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer; }
    @media only screen and (max-width: 56.25em) {
      .navigation__button {
        top: 4rem;
        right: 4rem; } }
    @media only screen and (max-width: 37.5em) {
      .navigation__button {
        top: 3rem;
        right: 3rem; 
        height: 3.5rem;
        width: 3.5rem;
      } }
  
  .navigation__background {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: fixed;
    top: 3.5rem;
    right: 3.5rem;
    background-image: radial-gradient(#403E3E, #a9a9a9);
    z-index: 1000;
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }
    @media only screen and (max-width: 56.25em) {
      .navigation__background {
        top: 4.5rem;
        right: 4.5rem; } }
    @media only screen and (max-width: 37.5em) {
      .navigation__background {
        height: 3rem;
        width: 3rem;
        top: 3.2rem;
        right: 3.2rem; 
        
      } }
  
  .navigation__nav {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    opacity: 0;
    width: 0;
    -webkit-transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  
  .navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%; }
  
  .navigation__item {
    margin: 1rem; }

    .navigation__item:nth-child(1){
        margin-bottom: 2rem;
    }
    .navigation__item:nth-child(2){
        margin-bottom: 2rem;
    } 
    .navigation__item:nth-child(3){
        margin-bottom: 5rem;
    }
  
  .navigation__link:link, .navigation__link:visited {
    display: inline-block;
    font-size: 4rem;
    letter-spacing: .4rem;
    font-weight: 300;
    font-family: 'ArmyRust';
    color: #CB8C00;
    padding: 1rem 2rem;
    text-decoration: none;
    text-transform: uppercase;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%);
    background-size: 220%;
    -webkit-transition: all .4s;
    transition: all .4s; }
    .navigation__link:link span, .navigation__link:visited span {
        margin-right: 1.5rem;
        display: inline-block; }
      @media only screen and (max-width: 56.25em) {
        ul{
          margin-left:0;
        }
        .navigation__link:link, .navigation__link:visited {
          font-size: 3.5rem;
        } }
      @media only screen and (max-width: 37.5em) {
        .navigation__link:link, .navigation__link:visited {
          font-size: 2.3rem;
        }
        }
  .navigation__link:hover, .navigation__link:active {
    background-position: 100%;
    color: #403E3E;
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem); }
  h1:hover{
    color: #403E3E;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  .navigation__checkbox:checked ~ .navigation__background {
    -webkit-transform: scale(80);
            transform: scale(80); }
  
  .navigation__checkbox:checked ~ .navigation__nav {
    opacity: 1;
    width: 100%; }
  
  .navigation__icon {
    position: relative;
    margin-top: 2.5rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 2.7rem;
      height: 4px;
      background-color: #333;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      -webkit-transition: all .2s;
      transition: all .2s; }
    .navigation__icon::before {
      top: -.8rem; }
    .navigation__icon::after {
      top: .8rem; }
    .navigation__button:hover .navigation__icon::before {
      top: -.9rem; }
    
    .navigation__button:hover .navigation__icon::after {
      top: .9rem; }
      
      @media only screen and (max-width: 37.5em) {
        .navigation__icon {
          margin-top: 1.75rem; } 
        .navigation__icon, .navigation__icon::before, .navigation__icon::after {
          width: 2.25rem;
          }
        .navigation__icon::before {
          top: -.65rem; }
        .navigation__icon::after {
          top: .65rem; }
        .navigation__button:hover .navigation__icon::before {
            top: -.65rem; }
          
        .navigation__button:hover .navigation__icon::after {
          top: .65rem; }
        }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
`


const Hamburger = () => (
    <Burger>
        <input type="checkbox" className="navigation__checkbox" id="navi-toggle"/>
        <label htmlFor="navi-toggle" className="navigation__button">
            <span className="navigation__icon">&nbsp;</span>
        </label>
        <div className="navigation__background">&nbsp;</div>
        <nav className="navigation__nav">
            <ul className="navigation__list">
                <li className="navigation__item"> <Link className="navigation__link" to="/about">Our Story</Link></li>
                <li className="navigation__item"><Link className="navigation__link" to="/services">Our Service</Link></li>
                <li className="navigation__item"><Link className="navigation__link" to="/team">Our Team</Link></li>
                <li className="navigation__item"><a href="mailto:danie@gbcontracting.org" className="navigation__link" style={{fontSize:'1.5rem'}}>Email Us!</a></li>
                <li className="navigation__item"><a href="tel:+14166782406" className="navigation__link" style={{fontSize:'1.5rem'}}>Call Us Now!</a></li>
            </ul>
        </nav>
    </Burger>
);

export default Hamburger;