import { StaticQuery, Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import logo from '../images/gbcontracting-white.png'
import Hamburger from './hamburger'
// import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'


const HeaderContent = styled.div`
  display:flex;
  flex-direction: column;
  height: 90vh;
  margin: auto;
  max-width: 100vw;
  padding: 0.5rem;
  img{
    margin-top: 1rem;
    margin-left: 3rem;
    margin-bottom: 0;
    width:12%;
  }
  h1{
    margin-top: 8rem;
    text-align: center;
  }
  @media only screen and (max-width: 56.25em) {
    img{
      margin-top: 1rem;
      margin-left: 3rem;
      margin-bottom: 0;
      width:30%;
    }
    h1{
      margin-top: 4rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: 37.5em) {
    img{
      margin-top: 1rem;
      margin-left: 1rem;
      margin-bottom: 0;
      width:30%;
    }
    h1{
      font-size: 3.6rem;
      margin-top: 8rem;
      text-align: center;
    }
  }
`

// const StyledBackgroundSection = styled(HeaderContent)`
// background-size: cover;
// background-position: top;
// `

const Header = ({ siteTitle }) => (
      <StaticQuery 
        query = {graphql`
        query HeaderTitleQuery {
          file(relativePath: {regex: "/bg/"}) {
            size
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }        
        `}
        render={data=> (
          <BackgroundImage Tag="section" 
            className="classname"
            fluid={data.file.childImageSharp.fluid}
            backgroundColor={`#040e18`}>
            <HeaderContent>
              <Link
                to="/"
              >
                <img src={logo} alt="gbcontracting logo"/>
              </Link >
              <h1>{siteTitle}</h1>
            </HeaderContent>
            <Hamburger/>
          </BackgroundImage>

        )}
      >
      </StaticQuery>
    
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
} 

export default Header
