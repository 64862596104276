// import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import logo from '../images/gbcontracting-white.png'

const FooterStyle = styled.div`
    background-color: #403E3E;
    text-align: center;
`

const ColumnDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding 1.45rem;
    
    h2{
        font-family: 'ArmyRust';
        font-size: 40px;
        color: #D9D8D8;
        font-weight: 300;
        
    }
    p{
        color: #C4BFBF;
        margin-bottom: .6rem;
    }
    a{
        color: #707070;
        text-decoration: none;
    }
    a:visited{
        color: #707070;
    }
    a:hover{
        color: #CB8C00;
    }
    @media only screen and (max-width: 56.25em) {
        display: flex;
        flex-direction: column;
    }
    @media only screen and (max-width: 37.5em) {
        display: flex;
        flex-direction: column;
    }
`

const Footer = () =>(
    <FooterStyle >
        <ColumnDiv>
            <div>
                <h2>Contacts</h2>
                <p><b>Phone Number: </b> 416.678.2406</p>
                <p><b>Email: </b> info@gbcontracting.org</p>
            </div>
            <div>
                <img style={{width: "50%", }} src={logo} alt=" gb contracting logo footer"></img>
                <div style={{color: '#707070'}}>© {new Date().getFullYear()} Gigliotti Brothers Contracting. All rights reserved, Built By
            {` `}
            <a style ={{}}href="https://www.tejlehal.com">TejLehal</a></div>
            </div>
            <div>
                <h2>Services</h2>
                <p>Basements</p>
                <p>Fencing</p>
                <p>Kitchen Remodelling</p>
                <p>Bathrooms</p>
                <p>Custom Renovation</p>
            </div>
        </ColumnDiv>
    </FooterStyle>
)


export default Footer;